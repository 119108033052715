import React from 'react';
import logo from '../assets/images/logo.png';
import '../assets/styles/Topbar.css';

const TopBar = () => {
    return (
        <div className="topbar">

<a href="/">            <img src={logo} alt="Company Logo" className="topbar-logo" />
</a>

            <div className="topbar-links">
              <a href="/apps" className="topbar-link">Apps</a>
              <a href="/about" className="topbar-link">About us</a>
            </div>
        </div>
    );
};

export default TopBar;



