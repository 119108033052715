import React from 'react';
import TopBar from '../components/Topbar';
import Footer from '../components/Footer';
import bgDesktop from '../assets/images/bg_home_desktop.png';
import bgMobile from '../assets/images/bg_home_mobile.png';
import '../assets/styles/Home.css';



const Home = () => (
<>
  <TopBar/>
  <Banner />
  <ImageSection />
  <Footer />
</>
);


function Banner() {
  return (
    <div className="banner">
      <h1>Your next obsession</h1>
      <p>New apps on the horizon, stay tuned!</p>
    </div>
  );
}

function ImageSection() {
  const [imageSrc, setImageSrc] = React.useState(bgDesktop);

  React.useEffect(() => {
    const updateImage = () => {
      if (window.innerWidth <= 768) {
        setImageSrc(bgMobile);
      } else {
        setImageSrc(bgDesktop);
      }
    };

    updateImage();
    window.addEventListener('resize', updateImage);
    return () => window.removeEventListener('resize', updateImage);
  }, []);

  return <img src={imageSrc} alt="Background" className="banner-image" />;
}

export default Home;
