import React from 'react';
import '../assets/styles/Footer.css'; 

const Footer = () => {
  return (
    <footer className="footer">
      Copyright © {new Date().getFullYear()} Atlasmana.
    </footer>
  );
};

export default Footer;
