import TopBar from '../components/Topbar';
import Footer from '../components/Footer';
import "../assets/styles/About.css";
import iconInsta from "../assets/images/ic_instagram.png" 
import iconMail from "../assets/images/ic_mail.png" 

const SocialLink = ({ href, imgSrc, alt, text }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" aria-label={text}>
    <img src={imgSrc} alt={alt} className="social-icon" />
    <span className="social-text">{text}</span>
  </a>
);

const About = () => (
  <>
    <div className="content-container">
      <div className="topbar-container">
        <TopBar />
      </div>
      <div className="about-us-section">
        <h1 className=".about-title">About Us</h1>
        <p>
        Welcome to Atlasmana! We are a dynamic team of mobile app developers, 
        dedicated to designing high-quality apps for both Android and iOS users. 
        Based in Tehran, we bring a unique blend of passion and innovation to every project.
        </p>
   
      </div>
      <div className="social-section">
        <div className="social-links">
          <SocialLink
            href="mailto:info@atlasmana.com"
            imgSrc={iconMail}
            alt="Email Icon"
            text="info@atlasmana.com"
          />
          <SocialLink
            href="https://www.instagram.com/atlasmana.co"
            imgSrc={iconInsta}
            alt="Instagram Icon"
            text="Atlasmana.co"
          />
        </div>
      </div>
    </div>
    <Footer />
  </>
);

export default About;
