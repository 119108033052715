import React from 'react';

const NotFound = () => (
  <div style={{ textAlign: 'center', marginTop: '50px' }}>
    <h1>404</h1>
    <p>Sorry, the page you are looking for does not exist.</p>
    <a href="/" style={{ color: '#00bfff', textDecoration: 'none' }}>
      Go back to the homepage
    </a>
  </div>
);

export default NotFound;
